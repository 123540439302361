import React from 'react'
import './App.css'
import Home from './pages/Home'
import About from './pages/About'
import Archive from './pages/Archive'
import { Route, Routes } from 'react-router-dom'
import RoastingRoberta from './pages/RoastingRoberta'

// App component
function App() {
    return (
        <div className="App">
            <Routes>
                <Route path="/" exact element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/archive" element={<Archive />} />
                <Route path="roastingroberta" element={<RoastingRoberta />} />
            </Routes>
        </div>
    )
}

export default App
